<template>
  <div>
    <h1>実績管理</h1>

    <v-card class="pa-5" flat outlined>
      <v-autocomplete
        v-model="selecthospital"
        :items="hospitals"
        outlined
        label="病院選択"
        item-text="name"
        item-value="code"
        chips
      >
      </v-autocomplete>
      <DatePicker
        v-model="date"
        label="対象年月"
        prepend-inner-icon="mdi-calendar"
        type="month"
        outlined
        next
        previous
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <Btn icon="send" color="success" @click="click('query')">取得</Btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="!!schedule && schedule.length" class="office mt-5">
      <v-toolbar color="primary">
        <!-- <v-btn class="mx-2" color="success" @click="add()"
          >スポット作業実績追加</v-btn
        >-->
      </v-toolbar>
      <template v-for="affiliation in affiliations">
        <table class="mt-4 mx-4" border="1" :key="affiliation.index">
          <thead>
            <tr>
              <th rowspan="1" class="fixed"></th>
              <th v-for="(cal, i) in schedule" :key="i">
                {{ cal.name }}
              </th>
            </tr>
          </thead>
          <template v-for="n in affiliation.scount">
            <template v-for="packagerow in affiliation.packages[n - 1]">
              <tr :key="packagerow.rowindex">
                <th v-if="packagerow.rowspan > 0" :rowspan="packagerow.rowspan">
                  <Btn
                    color="info"
                    text
                    small
                    @click="fix(affiliation.index, packagerow.index, 'query')"
                    >編集</Btn
                  >
                </th>
                <template v-for="meisai in packagerow.packagemeisais">
                  <td
                    :key="meisai.rowindex"
                    v-if="meisai.rowspan > 0"
                    :rowspan="meisai.rowspan"
                    style="white-space: pre-line; word-wrap: break-word;"
                    :class="meisai.width"
                    :bgcolor="meisai.color"
                  >
                    {{ meisai.name }}
                  </td>
                </template>
              </tr>
            </template>
          </template>
        </table>
      </template>
    </v-card>
  </div>
</template>

<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import moment from "moment";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";

import Api from "@/mixins/api";
import * as Dummy from "./dummy";
import ServiceRecord from "./dialogs/ServiceRecordFixDialog.vue";

const ViewTypes = [{ text: "月", value: "month" }];

const Selects = {
  User: 0,
  Office: 1,
  Me: 2
};

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + Number(DATE.getDate())).slice(-2);
const Users = [];
export default {
  name: "ServiceRecordMaintenance",
  mixins: [Common, ShowDialogs, Api],
  components: {
    //"vue-timepicker": VueTimepicker
    //VueGoodTable
  },
  data: () => ({
    menu: false,
    date:
      DATE.getFullYear() +
      "-" +
      ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + Number(DATE.getDate())).slice(-2),
    dateFrom: DATENOW,
    dateTo: DATENOW,
    select: Selects.User,
    records: [],
    isConfirm: false,
    attendances: { rows: [], columns: [] },
    summaries: { rows: [], columns: [] },
    total: { rows: [], columns: [] },
    cols: [],
    rows: [],
    summaryheader: [],
    summary: [],
    users: Users,
    user: Users[0],
    Selects,
    options: {
      select: {
        enabled: true,
        selectOnCheckboxOnly: true,
        disableSelectInfo: true
      },
      search: { enabled: true }
    },
    selected: [],
    shozokus: [],
    shozoku: { code: "", name: "" },
    backForCalc: false,
    backForShift: false,
    backForApprovalStatus: false,
    showConfirm: false,
    hospitals: [],
    selecthospital: { code: "", name: "" },
    schedule: []
  }),
  computed: {
    SummaryHeader() {
      return null;
    },
    Year() {
      return !this.date ? undefined : parseInt(this.date.split("-")[0]);
    },
    Month() {
      return !this.date ? undefined : parseInt(this.date.split("-")[1]);
    },
    Day() {
      if (!this.date) return undefined;
      const split = this.date.split("-");

      return split.length < 2 ? undefined : parseInt(split[2]);
    }
  },
  methods: {
    /*rowStyleClass(row) {
      console.log("rowStyleClass", row);
      return row.remarks === "1" ? "yellow" : "";
    },*/
    async getTargetMonth() {
      const startmonth = new Date(this.Year, this.Month, 21);
      let targetdt = new Date(this.Year, this.Month, this.Day);

      const m = moment(targetdt);
      if (targetdt == startmonth) {
        // targetdt.setMonth(targetdt.getMonth() + 1);
        targetdt = m.add(1, "M").toDate();
      } else if (targetdt > startmonth && targetdt.getDate() < 21) {
        //targetdt.setMonth(targetdt.getMonth() - 1);
        targetdt = m.add(-1, "M").toDate();
      } else if (targetdt < startmonth && targetdt.getDate() < 21) {
        // targetdt.setMonth(targetdt.getMonth() - 1);
        targetdt = m.add(-1, "M").toDate();
      }

      let targetMonth =
        targetdt.getFullYear() +
        "-" +
        ("00" + Number(targetdt.getMonth())).slice(-2);

      if (Number(targetdt.getMonth()) == 0) {
        const m2 = moment(targetdt);
        targetdt = m2.add(-1, "M").toDate();
        targetMonth =
          targetdt.getFullYear() +
          "-" +
          ("00" + Number(targetdt.getMonth() + 1)).slice(-2);
      }

      return targetMonth;
    },
    async confirm() {
      /*this.isConfirm = await this.$confirm(
        "勤務表を提出します。\nよろしいですか？"
      );*/
    },
    async click(pathparam) {
      console.log("click", pathparam);
      const date = new Date(this.date);
      const result = true;

      if (result) {
        this.$loading();
        try {
          this.schedule = await this.getShiftMonth(
            date.getFullYear(),
            date.getMonth() + 1,
            pathparam
          );
          console.log("schedule", this.schedule);
          this.tableTitle = this.format;
        } catch (e) {
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },
    async getShiftMonth(year, month, pathparam) {
      console.log("getShiftMonth", year, month, pathparam);
      // 選択した所属を取得
      console.log("selected", this.selecthospital);
      this.affiliations = [];
      //this.packages.packagemeisais = [];
      this.packages = [];
      this.schedules = [];
      const param = {
        affiliations: this.selecthospital,
        targetmonth: year + "-" + ("00" + month).slice(-2),
        scheduleflag: "0",
        unfinished: "0"
      };
      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);
      console.log("this.packages", this.packages);
      // API送信
      const result = await this.$get(
        this.Paths.schedule,
        pathparam + "=" + encode
      );

      console.log("api_result", result);
      if (result.length == 0) {
        await this.$warning("該当するデータがありません。", "実績データ取得");
      } else {
        return await this.setShiftCalendar(result, year, month);
      }
    },
    async setShiftCalendar(apidata, year, month) {
      console.log("setShiftCalendar", apidata);

      //データヘッダを作成
      const shedder = apidata.schedulehedder;
      console.log("schedulehedder", shedder);

      const schedules = [];
      for (let i = 0; i < shedder.length; i++) {
        schedules.push({
          index: Number(shedder[i].code),
          name: shedder[i].name
        });
      }

      //
      const hedder = apidata.hedder;

      for (let i = 0; i < hedder.length; i++) {
        const affiliation = hedder[i];

        const packages = [];
        console.log("affiliations", this.affiliations);
        const rowscount = [0];
        const rowindex = [0];

        for (let a = 0; a < affiliation.details.length; a++) {
          const b = affiliation.details[a];
          rowscount[0] = rowscount[0] + b.packagemeisai.length;
        }

        for (let j = 0; j < affiliation.details.length; j++) {
          const c = affiliation.details[j];

          //行の色設定
          //作業日が入っていない場合は、色を付ける
          const bgcolor = [""];
          if (c.executiondate == null || c.executiondate == "") {
            bgcolor[0] = "yellow";
          }
          /*if (c.scheduledate == "" && c.executiondate == "") {
            bgcolor[0] = "orange";
          }*/

          const packageList = [];
          const meisairowspan = c.packagemeisai.length;

          const tanto = [""];
          const tantonm = [""];
          const texec = [""];
          const wtanto = [""];
          const wtantonm = [""];
          const wtexec = [""];

          //担当者リストの作成
          for (let d = 0; d < c.tanto.length; d++) {
            if (d > 0) {
              tanto[0] = tanto[0] + ",";
              tantonm[0] = tantonm[0] + ",\r\n";
            }
            tanto[0] = tanto[0] + c.tanto[d].userid;
            tantonm[0] = tantonm[0] + c.tanto[d].username;
            texec[d] =
              c.tanto[d].resttime1 +
              "," +
              c.tanto[d].resttime2 +
              "," +
              c.tanto[d].resttime +
              "," +
              c.tanto[d].worktime +
              "," +
              c.tanto[d].overtime +
              "," +
              c.tanto[d].calcFlag;
          }

          //作業担当者のリスト
          for (let e = 0; e < c.worktanto.length; e++) {
            if (e > 0) {
              wtanto[0] = wtanto[0] + ",";
              wtantonm[0] = wtantonm[0] + ",\r\n";
            }
            wtanto[0] = wtanto[0] + c.worktanto[e].userid;
            wtantonm[0] = wtantonm[0] + c.worktanto[e].username;
            wtexec[e] =
              c.worktanto[e].resttime1 +
              "," +
              c.worktanto[e].resttime2 +
              "," +
              c.worktanto[e].resttime +
              "," +
              c.worktanto[e].worktime +
              "," +
              c.worktanto[e].overtime +
              "," +
              c.worktanto[e].calcFlag;
          }

          for (let k = 0; k < c.packagemeisai.length; k++) {
            const s = c.packagemeisai[k];
            const packagemeisaiList = [];
            const atime = c.assemblytime.split(":");
            const stime = c.executionstarttime.split(":");
            const etime = c.executionendtime.split(":");
            const ltime = c.leavetime.split(":");

            //作業箇所の情報を取得する
            const warea = [""];
            const wareasuu = [""];
            const wareazansuu = [""];
            const wareakbn = [""];
            const disp = [""];
            const add = [""];
            const st = [""];
            const sep = [""];
            const nm = [""];
            const hd = [""];
            const nc = [""];
            const ot = [""];
            const key = [""];
            const allncflag = [true];
            for (let l = 0; l < s.workarea.length; l++) {
              if (l > 0) {
                warea[0] = warea[0] + ",";
                wareasuu[0] = wareasuu[0] + ",";
                wareazansuu[0] = wareazansuu[0] + ",";
                wareakbn[0] = wareakbn[0] + ",";
                disp[0] = disp[0] + ",";
                add[0] = add[0] + ",";
                st[0] = st[0] + ",";
                sep[0] = sep[0] + ",";
                nm[0] = nm[0] + ",";
                hd[0] = hd[0] + ",";
                nc[0] = nc[0] + ",";
                ot[0] = ot[0] + ",";
                key[0] = key[0] + ",";
              }
              warea[0] = warea[0] + s.workarea[l].workarea;
              wareasuu[0] = wareasuu[0] + s.workarea[l].workareaSuu;
              wareazansuu[0] = wareazansuu[0] + s.workarea[l].workareaZansuu;
              wareakbn[0] = wareakbn[0] + s.workarea[l].workareaSuuKbn;
              disp[0] = disp[0] + s.workarea[l].displayflag;
              add[0] = add[0] + s.workarea[l].addflag;
              st[0] = st[0] + s.workarea[l].status;
              sep[0] = sep[0] + s.workarea[l].sepalationflag;
              nm[0] = nm[0] + s.workarea[l].processingschedulenextmonth;
              hd[0] = hd[0] + s.workarea[l].processingschedulehold;
              nc[0] = nc[0] + s.workarea[l].processingschedulenextcycle;
              ot[0] = ot[0] + s.workarea[l].processingscheduleother;
              key[0] = key[0] + s.workarea[l].referenceworkareaid;

              if (
                s.workarea[l].processingschedulenextcycle == "0" &&
                allncflag[0] == true
              ) {
                allncflag[0] = false;
              }
              /*if ((c.scheduledate == null || c.scheduledate == "") && bgcolor[0] == "yellow") {
                if (s.workarea[l].processingschedulenextcycle == "1") {
                  bgcolor[0] = "";
                }
              } */
            }

            if (
              (c.scheduledate == null || c.scheduledate == "") &&
              bgcolor[0] == "yellow" &&
              allncflag[0] == true
            ) {
              bgcolor[0] = "";
            }

            const prows = [0];

            if (k == 0) {
              prows[0] = meisairowspan;
            }

            //物件名～フロアまでは、固定
            //物件名
            if (j > 0 || k > 0) {
              packagemeisaiList.push({
                rowspan: 0,
                status: year + "-" + ("00" + month).slice(-2),
                code: "",
                sepalate: "",
                name: affiliation.hospitalName,
                width: "seven",
                color: bgcolor[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: rowscount[0],
                status: year + "-" + ("00" + month).slice(-2),
                code: "",
                sepalate: "",
                name: affiliation.hospitalName,
                width: "seven",
                color: bgcolor[0]
              });
            }
            //委託先
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.company,
              width: "five",
              color: bgcolor[0]
            });
            //作業内容
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.workpattern,
              width: "seven",
              color: bgcolor[0]
            });
            //作業予定日時
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.scheduledate,
              width: "five",
              color: bgcolor[0]
            });
            //作業日時
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.executiondate,
              width: "five",
              color: bgcolor[0]
            });
            //開始時刻
            if (stime.length > 1) {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: stime[0] + ":" + stime[1],
                width: "five",
                color: bgcolor[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: "",
                width: "five",
                color: bgcolor[0]
              });
            }
            //終了時刻
            if (etime.length > 1) {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: etime[0] + ":" + etime[1],
                width: "five",
                color: bgcolor[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: "",
                width: "five",
                color: bgcolor[0]
              });
            }
            //作業担当者
            packagemeisaiList.push({
              rowspan: prows[0],
              status: wtexec,
              code: wtanto[0],
              sepalate: "",
              name: wtantonm[0],
              width: "six",
              color: bgcolor[0]
            });
            //担当者
            packagemeisaiList.push({
              rowspan: prows[0],
              status: texec,
              code: tanto[0],
              sepalate: "",
              name: tantonm[0],
              width: "six",
              color: bgcolor[0]
            });
            //フロア
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.floor,
              width: "seven",
              color: bgcolor[0]
            });
            //エリア
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.area,
              width: "seven",
              color: bgcolor[0]
            });

            //作業箇所
            packagemeisaiList.push({
              rowspan: 1,
              status: st[0],
              code: add[0],
              sepalate: sep[0],
              name: warea[0],
              display: disp[0],
              nextmonth: nm[0],
              hold: hd[0],
              nextcycle: nc[0],
              other: ot[0],
              keyid: key[0],
              width: "workarea",
              color: bgcolor[0],
              refid: key[0],
              suu: wareasuu[0],
              zansuu: wareazansuu[0],
              kbn: wareakbn[0]
            });
            //備考
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.biko,
              width: "fifteen",
              color: bgcolor[0]
            });

            //その他引渡が必要な項目
            packagemeisaiList.push({
              rowspan: 0,
              addflag: s.addflag,
              sepalationflag: s.sepalationflag,
              referencemeisaiid: "",
              remainreson: c.remainreson,
              remainbiko: c.remainbiko,
              width: "",
              weekday: c.weekday
            });

            //以下の内容は、画面には表示させないがデータ処理場必須のため渡す(必須:rowspan=0)

            if (k == 0) {
              packageList.push({
                index: j,
                rowindex: rowindex[0],
                hojinCd: affiliation.hojinCd,
                hospitalCd: affiliation.hospitalCd,
                packageNo: c.packageNo,
                packageMeisaiNo: s.packageMeisaiNo,
                packagemeisais: packagemeisaiList,
                rowspan: meisairowspan,
                unfinished: false,
                assemblytime: atime[0] + ":" + atime[1],
                leavetime: ltime[0] + ":" + ltime[1],
                keyId: c.keyid,
                greeting: c.greeting,
                safety: c.safety,
                signage: c.signage,
                cone: c.cone,
                chain: c.chain,
                otherThing: c.otherThing,
                otherThingText: c.otherThingText,
                hospitalTanto: c.hospitalTanto,
                addReason: c.addReason,
                hospitalremainbiko: c.hospitalremainbiko,
                changeReason: c.changeReason,
                tantoUpdateInfo: c.tantoUpdateInfo,
                tantoUpdateReason: c.tantoUpdateReason,
                scheduledate: c.scheduledate,
                schedulereviewdate: c.schedulereviewdate
              });
            } else {
              packageList.push({
                index: j,
                rowindex: rowindex[0],
                hojinCd: affiliation.hojinCd,
                hospitalCd: affiliation.hospitalCd,
                packageNo: c.packageNo,
                packageMeisaiNo: s.packageMeisaiNo,
                packagemeisais: packagemeisaiList,
                rowspan: 0,
                unfinished: false,
                assemblytime: atime[0] + ":" + atime[1],
                leavetime: ltime[0] + ":" + ltime[1],
                keyId: c.keyid,
                greeting: c.greeting,
                safety: c.safety,
                signage: c.signage,
                cone: c.cone,
                chain: c.chain,
                otherThing: c.otherThing,
                otherThingText: c.otherThingText,
                hospitalTanto: c.hospitalTanto,
                addReason: c.addReason,
                hospitalremainbiko: c.hospitalremainbiko,
                changeReason: c.changeReason,
                tantoUpdateInfo: c.tantoUpdateInfo,
                tantoUpdateReason: c.tantoUpdateReason,
                scheduledate: c.scheduledate,
                schedulereviewdate: c.schedulereviewdate
              });
            }
            rowindex[0] = rowindex[0] + 1;
          }

          packages.push(packageList);
          console.log("packages", this.packages);
        }

        const scounts = packages.length;

        this.affiliations.push({
          hojinCd: affiliation.hojinCd,
          hospitalCd: affiliation.hospitalCd,
          hospitalName: affiliation.hospitalName,
          index: i,
          packages: packages,
          scount: scounts
        });
      }

      //this.scount = this.packages.length;
      console.log("scount", this.scount);

      const schedule = schedules;
      console.log("affiliations", this.affiliations);

      return schedule;
    },
    async setUpdateData(pcks, aindex) {
      this.$loading();
      console.log("setUpdateData", pcks);
      const affilitations = this.affiliations;
      const schedules = this.schedule;

      this.affiliations = [];
      //this.packages = [];
      this.schedule = [];

      this.schedule = schedules;
      affilitations[aindex].packages = pcks;
      //this.affiliations[aindex].packages = pcks;
      this.affiliations = affilitations;
      //this.packages = pcks;

      this.$unloading();
    },
    async getData(api) {
      let data;
      switch (this.select) {
        case Selects.Me:
        case Selects.User:
        default: {
          if (api) {
            this.$loading();

            try {
              data = await this.getRecordByUser();
              // TODO 暫定対応:シフトあり、実績なしのデータを非表示
              const bodies = [];
              data.body.forEach(e => {
                if (e.recordid !== null) bodies.push(e);
              });
              data.body = bodies;
            } catch (e) {
              console.log(e);
              this.$error(e.message);
            } finally {
              this.$unloading();
            }
          } else {
            data = Dummy.getPrivateRostar();
          }
          break;
        }
        case Selects.Office:
          if (api) {
            this.$loading();
            try {
              data = await this.getRecordByOffice();
            } catch (e) {
              console.log(e);
              this.$error(e.message);
            } finally {
              this.$unloading();
            }
          } else {
            data = Dummy.getOfficeRostar();
          }
          break;
      }
      await this.setAttendanceDetails(data);
    },
    async getRecordByUser() {
      const targetMonth = await this.getTargetMonth();
      const param = {
        incode: this.user.code ? this.user.code : this.user,
        targetmonth: targetMonth
      };
      console.log("getRecordByUser", param);
      const encode = encodeURI(JSON.stringify(param));
      return await this.$get(this.Paths.attendance, "query=" + encode);
    },
    async getRecordByOffice() {
      const param = {
        affiliationId: this.shozoku.code ? this.shozoku.code : this.shozoku,
        attendanceDateFrom: this.dateFrom,
        attendanceDateTo: this.dateTo
      };
      console.log("getRecordByOffice", param);
      const encode = encodeURI(JSON.stringify(param));
      return await this.$get(this.Paths.attendance, "query-office=" + encode);
    },
    async fix(aindex, pindex, pathparam) {
      console.log("fix", aindex + "-" + pindex);
      //console.log("fix-index", item.index);
      const packs = this.affiliations[aindex].packages[pindex];
      console.log(packs);

      // 予定日が入っていない場合は、処理不可
      if (packs[0].scheduledate === "") {
        this.$error(
          "予定日が登録されていません。\n予定管理から予定日設定してください。"
        );
        return;
      }

      const props = { args: packs };
      console.log(props);
      //item.employee = this.user.code ? this.user.code : this.user;
      const result = await this.$showDialog(ServiceRecord, props);

      console.log("result", result);

      if (result) {
        this.$loading();
        try {
          const date = new Date(this.date);

          this.schedule = await this.getShiftMonth(
            date.getFullYear(),
            date.getMonth() + 1,
            pathparam
          );
        } catch (e) {
          console.log(e);
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },
    async getHospital() {
      console.log("getHospital");
      return await this.$get(this.Paths.hpFilter);
    },
    async add() {
      const aff = [];
      const pack = [];
      const mei = [];
      const date = new Date(this.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      for (let i = 0; i < 11; i++) {
        if (i == 0) {
          mei.push({
            rowspan: 0,
            status: year + "-" + ("00" + month).slice(-2),
            code: "",
            sepalate: "",
            name: ""
          });
        } else {
          mei.push({
            rowspan: 0,
            status: "",
            code: "",
            sepalate: "",
            name: ""
          });
        }
      }

      pack.push({
        hojinCd: "",
        hospitalCd: "",
        index: "",
        packageMeisaiNo: "",
        packageNo: "",
        packagemeisais: mei,
        rowindex: "",
        rowspan: ""
      });

      aff.push(pack);

      const result = await this.$showDialog(ServiceRecord, aff);

      if (result) {
        this.schedule = await this.getShiftMonth(
          date.getFullYear(),
          date.getMonth() + 1,
          ""
        );
      }
    }
  },
  async created() {
    console.log("created", this.date);

    this.$loading();

    try {
      this.hospitals = await this.getHospital();

      ////await this.getUsers();
      //// await this.getShozoku();
      //this.setdate();
    } finally {
      this.$unloading();
    }
  },
  mounted() {
    console.log("mounted");
  }
};
</script>

<style></style>

<style lang="scss" scoped>
.office {
  table {
    //width: 99%;
    border-collapse: collapse;
    display: block;
  }
}
.five {
  width: 5%;
}
.six {
  width: 6%;
}
.seven {
  width: 7%;
}
.fifteen {
  width: 12%;
}
.workarea {
  width: 25%;
}
.bold-text {
  font-weight: bold;
}
.pulldown-z-index {
  z-index: 3;
}
.fixed {
  position: sticky;
  left: 0;
  color: #333;
  background-color: white;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
  }
}
.private {
  table {
    // border-collapse: collapse;
    width: 100%;
  }
  // td {
  //   height: 80px;
  // }
}
.person {
  width: 50px;
}
.person::after {
  content: "ms";
}
.shift-event {
  font-size: 12px;
}

.warning-col {
  background-color: #ffcc66;
}

.worktime-span {
  float: right;
  margin-right: 15px;
}
</style>
